<template>
    <div class="user-contact">
        <div class="header" :class="{editing}" @click.stop="toggleExpanded">
            <div class="header-text">
                <span class="name">
                    {{ title }}
                </span>
                <span v-if="contact.relationship" class="relationship">
                    &mdash; {{ contact.relationship }}
                </span>
            </div>
            <el-button
                v-if="!editing"
                class="edit"
                icon="el-icon-edit"
                @click.stop="startEditing"
            />
            <el-button
                v-else
                class="save"
                :icon="loading ? 'el-icon-loading' : 'el-icon-check'"
                :disabled="!formValid || loading"
                @click.stop="saveContact"
            />
            <el-popconfirm
                v-if="!editing"
                class="deleteConfirm"
                title="Delete this contact?"
                @confirm="deleteContact"
            >
                <el-button
                    slot="reference"
                    class="delete"
                    icon="el-icon-delete"
                    @click.stop
                />
            </el-popconfirm>
            <el-button
                v-else
                class="cancel"
                :icon="loading ? 'el-icon-loading' : 'el-icon-close'"
                :disabled="loading"
                @click.stop="cancelEditing"
            />
        </div>
        <el-form
            v-show="editing || expanded"
            v-model="editingContact"
            label-position="left"
            label-width="120px"
            class="userContactForm"
            :class="{readonly: !editing}"
        >
            <el-form-item prop="name" label="Full Name">
                <el-input
                    ref="nameInput"
                    v-model="editingContact.name"
                    name="name"
                    type="text"
                    :readonly="!editing"
                    :suffix-icon="editing ? '' : 'el-icon-copy-document'"
                    @focus="handleFocus"
                />
            </el-form-item>

            <el-form-item prop="relationship" label="Relationship">
                <el-input
                    v-model="editingContact.relationship"
                    name="relationship"
                    type="text"
                    :readonly="!editing"
                    :suffix-icon="editing ? '' : 'el-icon-copy-document'"
                    @focus="handleFocus"
                />
            </el-form-item>

            <el-form-item prop="mobile" label="Mobile">
                <el-input
                    v-model="editingContact.mobile"
                    name="mobile"
                    type="tel"
                    :readonly="!editing"
                    :suffix-icon="editing ? '' : 'el-icon-copy-document'"
                    @focus="handleFocus"
                />
            </el-form-item>

            <el-form-item prop="email" label="Email">
                <el-input
                    v-model="editingContact.email"
                    name="email"
                    type="email"
                    :readonly="!editing"
                    :suffix-icon="editing ? '' : 'el-icon-copy-document'"
                    @focus="handleFocus"
                />
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import auth from '@/utils/auth';
export default {
    name: 'add-card',
    props: {
        user: {
            type: Object,
            required: true,
        },
        contact: {
            type: Object,
            default: () => ({
                name: null,
                relationship: null,
                mobile: null,
                email: null,
            }),
        },
    },
    data() {
        return {
            loading: false,
            editing: false,
            expanded: false,
            editingContact: null,
        };
    },
    computed: {
        title() {
            return this.contact?.name ?? 'New contact';
        },
        formValid() {
            return (
                this.editingContact?.name &&
                (this.editingContact?.email || this.editingContact?.mobile)
            );
        },
    },
    watch: {
        contact: {
            handler(val) {
                if (val.id) {
                    this.setEditingContact();
                    this.editing = false;
                } else {
                    this.startEditing();
                }
            },
            immediate: true,
        },
    },
    methods: {
        setEditingContact() {
            this.editingContact = {...this.contact};
            if (this.contact.id) {
                this.editingContact.id = this.contact.id;
            }
            if (!this.contact.user) {
                this.editingContact.user = this.$fire.doc(
                    `users/${this.user.id}`
                );
            }
        },
        startEditing() {
            this.setEditingContact();
            this.editing = true;
            setTimeout(() => {
                this.$refs.nameInput?.focus();
            }, 50);
        },
        cancelEditing() {
            this.editing = false;
            this.$emit('cancel');
        },
        async saveContact() {
            console.log('Save contact', this.editingContact);
            this.loading = true;
            try {
                // make sure user field is a ref
                this.editingContact.user = this.$fire.doc(
                    `users/${this.user.id}`
                );
                let contact = null;
                if (this.editingContact.id) {
                    // edit contact
                    const id = this.editingContact.id;
                    delete this.editingContact.id;
                    contact = await this.$fire
                        .doc(`contacts/${id}`)
                        .update(this.editingContact);
                } else {
                    // add contact
                    contact = await this.$fire
                        .collection('contacts')
                        .add(this.editingContact);
                }
                this.$emit('saved', contact);
                this.editing = false;
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async deleteContact() {
            this.loading = true;
            try {
                if (this.contact.id) {
                    await this.$fire
                        .doc(`contacts/${this.contact.id}`)
                        .delete();
                    this.$emit('deleted');
                }
                this.editing = false;
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        toggleExpanded() {
            this.expanded = !this.expanded;
        },
        async handleFocus(event) {
            if (event.target.readOnly) {
                await navigator.clipboard.writeText(event.target.value);
                this.$notify.success({
                    message: 'Copied to clipboard',
                });
            } else {
                event.target.select();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.user-contact {
    background: $grey;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 10px 0;
    transition: background-color 0.25s;

    .header {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        margin: -10px;
        border-radius: 8px;
        &:hover:not(.editing) {
            background: rgba(0, 0, 0, 0.05);
            cursor: pointer;
        }

        .header-text {
            flex: 1;
            .name {
                font-size: 16px;
                font-weight: bold;
            }
            .relationship {
                font-size: 14px;
                margin-left: 5px;
            }
        }

        .deleteConfirm {
            margin-left: 10px;
        }
    }

    .el-button {
        padding: 12px;
    }

    .userContactForm {
        margin-top: 20px;
    }
}

::v-deep {
    .el-form-item {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    input:read-only {
        cursor: pointer;
    }
}
</style>
