import 'firebase/firestore';
import '@/utils/firebase';
import {firebaseFirestore} from '@/utils/firebase';
import * as filters from '@/filters';
import * as Sentry from '@sentry/browser';

export default {
    user: null,

    generateKeywords(user) {
        const keywordsName = filters.createKeywords(user.name);
        const keywordsCode = filters.createKeywords(user.code);
        const keywordsEmail = filters.createKeywords(user.contact_email);
        const keywordsNdis = filters.createKeywords(user.ndis_number);
        return [
            ...new Set([
                ...keywordsName,
                ...keywordsCode,
                ...keywordsEmail,
                ...keywordsNdis,
            ]),
        ];
    },

    async generateCode(name) {
        // find a unique 3 letter code for this user (always starting with name's first letter)
        name = name
            .trim()
            .replace(/[^\w\s]/gi, '')
            .toUpperCase();
        if (name.length < 3) return this.generateRandomCode(); // we can't make a code off this name!

        var words = name.split(' ');
        if (words.length === 1) {
            // only one name; split into 1 letter 'words' instead
            words = words[0].split('');
        }

        if (words.length > 0) {
            // if name has 3 or more words, try 1 letter from each word first
            // eg. for 4 words: 124, 134, 123
            if (words.length >= 3) {
                var l2 = 1; // 2nd letter, start at the 2nd word and work forward
                var l3 = words.length - 1; // 3rd letter, start at the last word and work back
                do {
                    let code = words[0].charAt(0);
                    code += words[l2].charAt(0);
                    code += words[l3].charAt(0);

                    if (await this.isCodeUnique(code)) {
                        return code;
                    }

                    if (++l2 >= l3) {
                        l2 = 1;
                        --l3;
                    }
                } while (l3 >= 2); // once l3 is at the third word, we've run out of combos
            }

            // check for code with 1 letter from 1 word, 2 letters from another word
            // Pattern 1,12 12,1 1,13 13,1 1,14 14,1 etc

            var spos = 0; // single letter starts on 1st word
            var ppos = words.length - 1; // letter pair starts on last word
            var p2 = 1; // letter pair 2nd char position

            do {
                var validWord = false; // at least one word of valid size was found
                do {
                    let code = '';
                    if (words[ppos].length >= p2 + 1) {
                        validWord = true;
                        if (spos < ppos) {
                            code += words[spos].charAt(0);
                            code += words[ppos].charAt(0);
                            code += words[ppos].charAt(p2);
                        } else {
                            code += words[ppos].charAt(0);
                            code += words[ppos].charAt(p2);
                            code += words[spos].charAt(0);
                        }
                        if (await this.isCodeUnique(code)) {
                            return code;
                        }
                    }

                    if (ppos > 0) {
                        --ppos;
                        if (ppos === 0) {
                            spos = words.length - 1;
                        }
                    } else if (spos > 0) --spos;
                } while (ppos !== spos);

                ++p2;
                spos = 0;
                ppos = words.length - 1;
            } while (validWord);
        }
        // all other options failed
        return this.generateRandomCode();
    },

    async generateRandomCode() {
        // worst case, just generate 3 random letters
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var code = '';
        var unique = false;
        do {
            for (var i = 0; i < 4; i++) {
                code += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            unique = this.isCodeUnique(code);
        } while (!unique);

        return code;
    },

    async isCodeUnique(code, userId = null) {
        const result = await firebaseFirestore
            .collection('users')
            .where('code', '==', code)
            .get();

        if (userId) {
            if (result.size > 1) {
                // duplicates already exist, log details
                const userIds = result.docs.map((u) => u.id).join(',');
                const message = `Duplicate participant code: ${code} [${userIds}]`;
                Sentry.captureMessage(message);
                return false;
            }
            if (result.size === 1) {
                if (userId !== result.docs[0].id) {
                    return false;
                }
            }
            return true;
        }
        return result.size <= 0;
    },
};
