var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      !_vm.loading && _vm.user
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "user-panel", attrs: { span: 8 } },
                [
                  _c("user-panel", {
                    staticClass: "content",
                    attrs: { user: _vm.user },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "plan-panel", attrs: { span: 16 } },
                [
                  _vm.is_valid_user
                    ? _c("plan-panel", { attrs: { user: _vm.user } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }