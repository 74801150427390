<template>
    <div v-loading="loading" class="app-container">
        <el-row v-if="!loading && user" :gutter="20">
            <el-col :span="8" class="user-panel">
                <user-panel :user="user" class="content" />
            </el-col>
            <el-col :span="16" class="plan-panel">
                <plan-panel v-if="is_valid_user" :user="user" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import UserPanel from '@/views/components/UserPanel';
import PlanPanel from '@/views/components/PlanPanel';
import * as Sentry from '@sentry/browser';

export default {
    name: 'new-user',
    components: {
        UserPanel,
        PlanPanel,
    },
    data() {
        return {
            user: {
                ndis_number: null,
                name: null,
                code: null,
                mobile: null,
                phone: null,
                dob: null,
                address: null,
                contact_email: null,
            },
            loading: true,
        };
    },
    computed: {
        is_valid_user() {
            if (!this.user) return false;
            return this.user.id !== null;
        },
    },
    watch: {
        $route: function (val) {
            this.bindUser();
        },
    },
    mounted() {
        this.bindUser();
    },
    methods: {
        async bindUser() {
            if (this.$route.params.user_id !== undefined) {
                await this.$bind(
                    'user',
                    this.$fire.doc(`users/${this.$route.params.user_id}`),
                    {maxRefDepth: 1}
                )
                    .then((user) => {
                        if (user === null) {
                            // user not found, redirect to user list
                            this.$router.push('/users');
                        }
                        this.loading = false;
                    })
                    .catch((e) => {
                        console.log('User error: ', e);
                        this.$notify.error({
                            title: 'Error',
                            message: 'Unable to load user profile',
                        });
                        this.loading = false;
                        Sentry.captureException(e);
                    });
            } else {
                this.user = {
                    id: null,
                    ndis_number: null,
                    name: null,
                    gender: null,
                    code: null,
                    mobile: null,
                    phone: null,
                    dob: null,
                    address: null,
                    contact_email: null,
                };
                this.loading = false;
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-container {
    padding: 0;
    min-height: calc(100vh - 50px);
    overflow: hidden;

    .el-row {
        margin: 0 !important;
    }
}

.user-panel {
    padding: 10px 40px 50px 40px !important;
    box-sizing: border-box;
    background-color: white;
    height: calc(100vh - 50px);
    overflow: scroll;
}

.plan-panel {
    padding: 0 40px 10px 40px !important;
    box-sizing: border-box;
    background-color: #f0f2f5;
    height: calc(100vh - 50px);
    overflow: scroll;
}
</style>
