var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comments-block" }, [
    _c(
      "ul",
      _vm._l(_vm.comments, function (comment) {
        return _c("comment-row", {
          key: comment.id,
          attrs: { comment: comment },
          on: { delete: _vm.deleteComment },
        })
      }),
      1
    ),
    _c(
      "div",
      { staticClass: "new" },
      [
        _c("el-input", {
          attrs: {
            name: "text",
            type: "text",
            maxlength: 150,
            placeholder: "Add comment",
            size: "mini",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.addComment.apply(null, arguments)
            },
          },
          model: {
            value: _vm.text,
            callback: function ($$v) {
              _vm.text = $$v
            },
            expression: "text",
          },
        }),
        _c("el-button", {
          attrs: {
            size: "mini",
            type: "primary",
            icon: "el-icon-d-arrow-right",
          },
          on: { click: _vm.addComment },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }