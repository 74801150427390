<template>
    <el-form
        ref="form"
        auto-complete="on"
        :model="user"
        :rules="rules"
        label-position="left"
        label-width="120px"
        autocomplete="off"
    >
        <h3>Participant</h3>

        <i
            class="user-settings-button el-icon-setting"
            @click="showUserSettings"
        />

        <el-alert
            v-if="is_valid_user && !is_user_active"
            style="margin-bottom: 20px"
            type="error"
            :closable="false"
            title="Warning"
            description="This user is currently inactive."
        />
        <el-alert
            v-if="ndis_number_in_use"
            ref="alert"
            style="margin-bottom: 20px"
            type="error"
            :closable="false"
            title="Warning"
            description="There is already a user with this NDIS number."
        />

        <el-form-item prop="ndis_number" label="NDIS Number">
            <el-input
                v-model="user.ndis_number"
                name="ndis_number"
                type="text"
                :maxlength="9"
                auto-complete="on"
                placeholder="NDIS Number"
                :disabled="is_valid_user"
                autocomplete="off"
            />
        </el-form-item>
        <el-form-item prop="name" label="Name">
            <el-input
                v-model="user.name"
                name="name"
                type="text"
                autocomplete="off"
            />
        </el-form-item>

        <div class="approval-required-block">
            <span>Require approval for all claims</span>
            <el-switch v-model="user.require_approval" />
        </div>

        <hr />

        <comments-block :user="user" />

        <el-radio-group v-model="selectedTab" class="user-tabs">
            <el-radio-button label="details">Personal Details</el-radio-button>
            <el-radio-button label="contacts">Contacts</el-radio-button>
            <el-radio-button label="invited">Invited Users</el-radio-button>
        </el-radio-group>

        <div v-if="selectedTab == 'details'">
            <el-form-item prop="gender" label="Gender">
                <el-select
                    v-model="gender"
                    filterable
                    allow-create
                    default-first-option
                    placeholder="Unknown"
                >
                    <el-option-group
                        label="Select an option or type a custom value"
                    >
                        <el-option
                            v-for="item in genders"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>

            <el-form-item prop="mobile" label="Mobile">
                <el-input
                    v-model="user.mobile"
                    name="mobile"
                    type="text"
                    autocomplete="off"
                />
            </el-form-item>

            <el-form-item prop="dob" label="DOB">
                <el-date-picker
                    v-model="dob"
                    type="date"
                    format="dd/MM/yyyy"
                    prefix-icon="-"
                />
            </el-form-item>

            <el-form-item prop="address" label="Address">
                <el-input v-model="user.address" name="address" type="text" />
            </el-form-item>

            <el-form-item props="state" label="State">
                <el-select v-model="user.state" placeholder="Choose state">
                    <el-option
                        v-for="state in states"
                        :key="state"
                        :label="state"
                        :value="state"
                    />
                </el-select>
            </el-form-item>

            <el-form-item prop="contact_email" label="Contact email">
                <el-input
                    v-model="user.contact_email"
                    name="contact_email"
                    type="email"
                    autocomplete="off"
                />
            </el-form-item>

            <p v-if="!is_valid_user" class="info">
                If left blank, a code will be autogenerated.
            </p>

            <el-form-item prop="code" label="Code">
                <el-input
                    v-model="user.code"
                    name="code"
                    type="text"
                    class="uppercase"
                    autocomplete="off"
                />
            </el-form-item>

            <div v-if="!is_valid_user">
                <p class="info">
                    The user will receive an email with the invite to download
                    the app and login with a temporary password.
                </p>
                <el-button
                    type="primary"
                    style="width: 100%"
                    :loading="loading"
                    @click="handleSubmit"
                >
                    Create
                </el-button>
            </div>
            <div v-else>
                <el-button
                    style="width: 100%"
                    :loading="loading"
                    @click="handleSubmit"
                >
                    Update
                </el-button>
            </div>
        </div>

        <div v-if="selectedTab === 'contacts'">
            <user-contact
                v-if="adding_contact"
                :user="user"
                @cancel="handleCancelAddContact"
                @saved="handleSavedContact"
            />
            <add-card
                v-else
                title="Add contact?"
                button_label="Add contact"
                @click="handleAddContact"
            />

            <user-contact
                v-for="(contact, index) in sorted_contacts"
                :key="contact.name + index"
                :user="user"
                :contact="contact"
            />
        </div>

        <div v-show="selectedTab === 'invited'">
            <linked-users
                v-if="is_user_active"
                :user="user"
                :admin="true"
                @show="handleShowUser"
            />
        </div>

        <el-dialog :visible.sync="user_settings_visible" width="50%" center>
            <div class="user-settings">
                <p v-if="!reset_password" class="info">
                    Set a new temporary password for this user.
                </p>
                <p v-if="reset_password === true && temp_password" class="info">
                    <i style="color: green" class="el-icon-circle-check" />
                    Temporary password for this user is:
                    <b
                        class="password"
                        @click="
                            () => {
                                copyToClipboard(temp_password);
                            }
                        "
                    >
                        {{ temp_password }}
                    </b>
                    <br />
                    <span class="footnote">
                        (Copy this password now, it won't be shown again.)
                    </span>
                </p>
                <p
                    v-if="reset_password === true && !temp_password"
                    class="info"
                >
                    <i style="color: green" class="el-icon-circle-check" />
                    Temporary password has been sent to this user's contact
                    email.
                </p>

                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-button
                            class="pwd-button"
                            :disabled="reset_password === false"
                            :loading="reset_password === false"
                            type="primary"
                            @click="() => handleResetPassword(true)"
                        >
                            Reset password &amp; view
                        </el-button>
                    </el-col>
                    <el-col :xs="24">
                        <el-button
                            class="pwd-button"
                            :disabled="
                                reset_password === false || !user.contact_email
                            "
                            :loading="reset_password === false"
                            type="primary"
                            @click="() => handleResetPassword(false)"
                        >
                            Reset password &amp; send email
                        </el-button>
                    </el-col>
                </el-row>

                <hr />

                <el-button
                    v-if="is_user_active"
                    style="width: 100%"
                    type="danger"
                    @click="deactivate_dialog_visible = true"
                >
                    Deactivate user
                </el-button>
                <el-button
                    v-else
                    style="width: 100%"
                    type="primary"
                    @click="changeUserStatus"
                >
                    Activate user
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="Warning"
            :visible.sync="deactivate_dialog_visible"
            width="30%"
            center
        >
            <span>
                Are you sure you want to deactivate this participant?<br />
                Any accounts monitoring, or monitored by, this user will be
                disconnected from it.
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="deactivate_dialog_visible = false">
                    Cancel
                </el-button>
                <el-button
                    type="danger"
                    :loading="changing_user_status"
                    @click="changeUserStatus"
                >
                    {{ is_user_active ? 'Deactivate' : 'Activate' }}
                </el-button>
            </span>
        </el-dialog>
    </el-form>
</template>

<script>
import {isValidEmail} from '@/utils/validate';
import axios from 'axios';
import userTools from '@/utils/userTools';
import CommentsBlock from '@/views/components/CommentsBlock';
import LinkedUsers from '@/views/client/LinkedUsers';
import * as Sentry from '@sentry/browser';
import AddCard from '@/views/components/AddCard.vue';
import UserContact from '@/views/components/UserContact';
import {API_CODES} from '@/utils/consts';

export default {
    name: 'user-panel',
    components: {
        AddCard,
        CommentsBlock,
        LinkedUsers,
        UserContact,
    },
    props: {
        user: {
            required: true,
            type: Object,
        },
    },
    data() {
        const validateEmail = (rule, value, callback) => {
            if (value && !isValidEmail(value))
                callback(new Error('Invalid Email'));
            else callback();
        };
        const validateNDISNumber = (rule, value, callback) => {
            if (value && value.length === 9) callback();
            else callback(new Error('Invalid NDIS Number'));
        };
        const validateCode = async (rule, value, callback) => {
            if (value) {
                const result = await userTools.isCodeUnique(
                    value,
                    this.user?.id || null
                );
                if (result) callback();
                else callback('Code is not unique');
            } else callback();
        };

        return {
            rules: {
                ndis_number: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: validateNDISNumber,
                        message: 'NDIS Number is required',
                    },
                ],
                name: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: 'User name is required',
                    },
                ],
                contact_email: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: validateEmail,
                    },
                ],
                code: [
                    {
                        required: false,
                        trigger: 'blur',
                        transform: (value) => value?.toUpperCase() ?? null,
                        validator: validateCode,
                    },
                ],
            },
            loading: false,
            reset_password: null,
            temp_password: null,
            changing_user_status: false,
            deactivate_dialog_visible: false,
            user_settings_visible: false,
            ndis_number_in_use: false,
            dob: this.user.dob ? new Date(this.user.dob.seconds * 1000) : null,
            adding_contact: false,
            contact_people: [],

            states: [
                'Australian Capital Territory',
                'New South Wales',
                'Northern Territory',
                'Queensland',
                'South Australia',
                'Tasmania',
                'Victoria',
                'Western Australia',
            ],

            genders: [
                {value: null, label: 'Unknown'},
                {value: 'Male', label: 'Male'},
                {value: 'Female', label: 'Female'},
            ],
            gender: null,

            selectedTab: 'details',
        };
    },
    computed: {
        is_valid_user() {
            if (!this.user) return false;
            return this.user.id != null;
        },
        is_user_active() {
            return this.user.status === 'active';
        },
        sorted_contacts() {
            return [...this.contact_people].sort((a, b) => {
                return a.name?.localeCompare(b.name);
            });
        },
    },
    watch: {
        'user.id': {
            handler(val) {
                if (val) {
                    const userRef = this.$fire.doc(`users/${val}`);
                    this.$bind(
                        'contact_people',
                        this.$fire
                            .collection('contacts')
                            .where('user', '==', userRef),
                        {maxRefDepth: 0}
                    );
                } else {
                    this.contact_people = [];
                }
            },
            immediate: true,
        },
        'user.ndis_number': function (val) {
            // hide duplicate number warning on change
            this.ndis_number_in_use = false;
        },
        'user.gender': {
            handler(val) {
                if (val === undefined) {
                    this.gender = null;
                } else {
                    this.gender = val;
                }
            },
            immediate: true,
        },
        'user.require_approval': function (val) {
            // if the approval toggle changes, immediately update user record
            if (this.user.id) {
                this.$fire
                    .doc(`users/${this.user.id}`)
                    .update({require_approval: val});
            }
        },
        'user.code': function (val) {
            this.user.code = val?.toUpperCase() ?? null;
        },
    },
    methods: {
        async handleSubmit() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    this.loading = true;
                    this.ndis_number_in_use = false;

                    this.user.gender = this.gender;

                    if (!this.is_valid_user) {
                        this.user.email =
                            this.user.ndis_number +
                            process.env.VUE_APP_EMAIL_DOMAIN;
                        this.user.is_temporary_password = true;
                        this.user.status = 'active';
                        this.user.dob = this.dob;

                        // generate code and check for dupes
                        if (!this.user.code) {
                            this.user.code = await userTools.generateCode(
                                this.user.name
                            );
                        }

                        this.user.keywords = userTools.generateKeywords(
                            this.user
                        );
                        delete this.user.id;

                        this.createUser(
                            this.user.ndis_number,
                            this.user.name,
                            this.user.contact_email
                        )
                            .then((result) => {
                                const uid = result.data.data.uid;

                                this.$fire.doc(`users/${uid}`).set(this.user);
                                this.loading = false;
                                this.$router.push(`/user/${uid}`);
                            })
                            .catch((e) => {
                                this.loading = false;
                                const api_error = e.response.data;
                                if (
                                    api_error?.code ===
                                    API_CODES.NDIS_ALREADY_USED
                                ) {
                                    // show warning on page and scroll into view
                                    this.ndis_number_in_use = true;
                                    this.$nextTick(() => {
                                        this.$refs.alert.$el.scrollIntoView();
                                    });
                                }
                                this.$notify.error({
                                    title: 'Unable to create user',
                                    message:
                                        api_error?.data?.message ??
                                        'An error occurred',
                                });
                                Sentry.captureException(e);
                            });
                    } else {
                        const data = Object.assign({}, this.user);
                        delete data.plans;
                        delete data.id;
                        delete data.status;
                        delete data.role;
                        delete data.is_temporary_password;

                        data.dob = this.dob;
                        data.keywords = userTools.generateKeywords(this.user);

                        await this.$fire
                            .doc(`users/${this.user.id}`)
                            .update(data);

                        setTimeout(() => {
                            this.loading = false;
                        }, 300);
                    }
                }
            });
        },
        handleResetPassword(display_password) {
            this.reset_password = false;

            let postdata = {ndis_number: this.user.ndis_number};
            if (display_password) postdata['display_password'] = 'true';

            axios
                .post(
                    `${process.env.VUE_APP_BASE_URI}/user/password/reset`,
                    postdata
                )
                .then((response) => {
                    this.temp_password = response.data.data?.password ?? null; // null if email was sent
                    this.reset_password = true;
                })
                .catch((e) => {
                    this.reset_password = null;
                    this.$notify.error({
                        title: 'Error',
                        message: e.message,
                    });
                    Sentry.captureException(e);
                });
        },
        changeUserStatus() {
            this.changing_user_status = true;
            this.deactivate_dialog_visible = false;

            setTimeout(async () => {
                await this.$fire.doc(`users/${this.user.id}`).update({
                    status: this.is_user_active ? 'inactive' : 'active',
                });

                this.changing_user_status = false;

                if (!this.is_user_active) {
                    // disconnect all managed and managing users
                    for (const u of this.user.managedUsers || []) {
                        await this.removeUser(this.user.id, u.id);
                    }
                    this.user.managedUsers = [];

                    for (const u of this.user.managedBy || []) {
                        await this.removeUser(u.id, this.user.id);
                    }
                    this.user.managedBy = [];

                    // Delete any expired plan cache documents
                    await this.$fire
                        .collection('expired_cache')
                        .doc(this.user.id)
                        .delete();
                }
            }, 300);
        },
        async createUser(ndis_number, name, email) {
            return axios.post(`${process.env.VUE_APP_BASE_URI}/user/create`, {
                ndis_number,
                name,
                email,
            });
        },
        handleShowUser(userId) {
            this.$router.push(`/user/${userId}`);
        },
        // TODO copied from LinkedUsers -- move both to utils
        async removeUser(managingUserID, managedUserID) {
            const managingUserSnapshot = await this.$fire
                .doc(`/users/${managingUserID}`)
                .get();
            const managedUserSnapshot = await this.$fire
                .doc(`/users/${managedUserID}`)
                .get();

            let managingUser = managingUserSnapshot.data();
            let managedUser = managedUserSnapshot.data();

            // remove managedUser's ID from managingUser's managedUsers list
            if (managingUser.managedUsers) {
                managingUser.managedUsers = managingUser.managedUsers.filter(
                    (u) => u.id != managedUserSnapshot.id
                );
            }

            // remove managingUser's ID from managedUser's managedBy list
            if (managedUser.managedBy) {
                managedUser.managedBy = managedUser.managedBy.filter(
                    (u) => u.id != managingUserSnapshot.id
                );
            }

            try {
                // perform both updates in one transaction
                await this.$fire.runTransaction(async (transaction) => {
                    await transaction.update(managingUserSnapshot.ref, {
                        managedUsers: managingUser.managedUsers,
                    });
                    await transaction.update(managedUserSnapshot.ref, {
                        managedBy: managedUser.managedBy,
                    });
                });
                return true;
            } catch (e) {
                console.log(e);
                this.$notify.error({
                    title: 'Error',
                    message: 'Error removing user',
                });
                return false;
            }
        },

        handleAddContact() {
            this.adding_contact = true;
        },
        handleCancelAddContact() {
            this.adding_contact = false;
        },
        handleSavedContact() {
            this.adding_contact = false;
        },

        showUserSettings() {
            this.user_settings_visible = true;
        },

        async copyToClipboard(value) {
            await navigator.clipboard.writeText(value);
            this.$notify.success({
                message: 'Copied to clipboard',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
hr {
    border: none;
    background: none;
    border-top: 1px solid #eef1f6;
    margin: 10px 0;
}

.el-date-editor {
    width: 100%;
}

.disabled {
    pointer-events: none;
}

.el-select {
    width: 100%;
}

.pwd-button {
    width: 100%;
    margin-bottom: 5px;
}

.el-form {
    position: relative;
}

.user-settings-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 20px;
    cursor: pointer;
}

.user-settings {
    margin: 20px auto 60px;
    max-width: 500px;

    .info {
        margin-bottom: 20px;
        font-size: 14px;
        color: #808080;

        .footnote {
            margin-top: 10px;
            font-size: 12px;
            display: block;
        }

        .password {
            color: #404040;
            background-color: #e0e0e0;
            padding: 2px 5px;
            border-radius: 3px;
            cursor: pointer;
        }
    }
}

.user-tabs {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
    label {
        width: 33.3%;
    }
    ::v-deep .el-radio-button__inner {
        width: 100%;
    }
}

.approval-required-block {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #606266;
    height: 40px;
    span {
        margin-right: 10px;
    }
}
</style>
