var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        "auto-complete": "on",
        model: _vm.user,
        rules: _vm.rules,
        "label-position": "left",
        "label-width": "120px",
        autocomplete: "off",
      },
    },
    [
      _c("h3", [_vm._v("Participant")]),
      _c("i", {
        staticClass: "user-settings-button el-icon-setting",
        on: { click: _vm.showUserSettings },
      }),
      _vm.is_valid_user && !_vm.is_user_active
        ? _c("el-alert", {
            staticStyle: { "margin-bottom": "20px" },
            attrs: {
              type: "error",
              closable: false,
              title: "Warning",
              description: "This user is currently inactive.",
            },
          })
        : _vm._e(),
      _vm.ndis_number_in_use
        ? _c("el-alert", {
            ref: "alert",
            staticStyle: { "margin-bottom": "20px" },
            attrs: {
              type: "error",
              closable: false,
              title: "Warning",
              description: "There is already a user with this NDIS number.",
            },
          })
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { prop: "ndis_number", label: "NDIS Number" } },
        [
          _c("el-input", {
            attrs: {
              name: "ndis_number",
              type: "text",
              maxlength: 9,
              "auto-complete": "on",
              placeholder: "NDIS Number",
              disabled: _vm.is_valid_user,
              autocomplete: "off",
            },
            model: {
              value: _vm.user.ndis_number,
              callback: function ($$v) {
                _vm.$set(_vm.user, "ndis_number", $$v)
              },
              expression: "user.ndis_number",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "name", label: "Name" } },
        [
          _c("el-input", {
            attrs: { name: "name", type: "text", autocomplete: "off" },
            model: {
              value: _vm.user.name,
              callback: function ($$v) {
                _vm.$set(_vm.user, "name", $$v)
              },
              expression: "user.name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "approval-required-block" },
        [
          _c("span", [_vm._v("Require approval for all claims")]),
          _c("el-switch", {
            model: {
              value: _vm.user.require_approval,
              callback: function ($$v) {
                _vm.$set(_vm.user, "require_approval", $$v)
              },
              expression: "user.require_approval",
            },
          }),
        ],
        1
      ),
      _c("hr"),
      _c("comments-block", { attrs: { user: _vm.user } }),
      _c(
        "el-radio-group",
        {
          staticClass: "user-tabs",
          model: {
            value: _vm.selectedTab,
            callback: function ($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: "details" } }, [
            _vm._v("Personal Details"),
          ]),
          _c("el-radio-button", { attrs: { label: "contacts" } }, [
            _vm._v("Contacts"),
          ]),
          _c("el-radio-button", { attrs: { label: "invited" } }, [
            _vm._v("Invited Users"),
          ]),
        ],
        1
      ),
      _vm.selectedTab == "details"
        ? _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { prop: "gender", label: "Gender" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        placeholder: "Unknown",
                      },
                      model: {
                        value: _vm.gender,
                        callback: function ($$v) {
                          _vm.gender = $$v
                        },
                        expression: "gender",
                      },
                    },
                    [
                      _c(
                        "el-option-group",
                        {
                          attrs: {
                            label: "Select an option or type a custom value",
                          },
                        },
                        _vm._l(_vm.genders, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "mobile", label: "Mobile" } },
                [
                  _c("el-input", {
                    attrs: {
                      name: "mobile",
                      type: "text",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.user.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "mobile", $$v)
                      },
                      expression: "user.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "dob", label: "DOB" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "dd/MM/yyyy",
                      "prefix-icon": "-",
                    },
                    model: {
                      value: _vm.dob,
                      callback: function ($$v) {
                        _vm.dob = $$v
                      },
                      expression: "dob",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "address", label: "Address" } },
                [
                  _c("el-input", {
                    attrs: { name: "address", type: "text" },
                    model: {
                      value: _vm.user.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "address", $$v)
                      },
                      expression: "user.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { props: "state", label: "State" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "Choose state" },
                      model: {
                        value: _vm.user.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "state", $$v)
                        },
                        expression: "user.state",
                      },
                    },
                    _vm._l(_vm.states, function (state) {
                      return _c("el-option", {
                        key: state,
                        attrs: { label: state, value: state },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "contact_email", label: "Contact email" } },
                [
                  _c("el-input", {
                    attrs: {
                      name: "contact_email",
                      type: "email",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.user.contact_email,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "contact_email", $$v)
                      },
                      expression: "user.contact_email",
                    },
                  }),
                ],
                1
              ),
              !_vm.is_valid_user
                ? _c("p", { staticClass: "info" }, [
                    _vm._v(" If left blank, a code will be autogenerated. "),
                  ])
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { prop: "code", label: "Code" } },
                [
                  _c("el-input", {
                    staticClass: "uppercase",
                    attrs: { name: "code", type: "text", autocomplete: "off" },
                    model: {
                      value: _vm.user.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "code", $$v)
                      },
                      expression: "user.code",
                    },
                  }),
                ],
                1
              ),
              !_vm.is_valid_user
                ? _c(
                    "div",
                    [
                      _c("p", { staticClass: "info" }, [
                        _vm._v(
                          " The user will receive an email with the invite to download the app and login with a temporary password. "
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { type: "primary", loading: _vm.loading },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v(" Create ")]
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { loading: _vm.loading },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v(" Update ")]
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedTab === "contacts"
        ? _c(
            "div",
            [
              _vm.adding_contact
                ? _c("user-contact", {
                    attrs: { user: _vm.user },
                    on: {
                      cancel: _vm.handleCancelAddContact,
                      saved: _vm.handleSavedContact,
                    },
                  })
                : _c("add-card", {
                    attrs: {
                      title: "Add contact?",
                      button_label: "Add contact",
                    },
                    on: { click: _vm.handleAddContact },
                  }),
              _vm._l(_vm.sorted_contacts, function (contact, index) {
                return _c("user-contact", {
                  key: contact.name + index,
                  attrs: { user: _vm.user, contact: contact },
                })
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectedTab === "invited",
              expression: "selectedTab === 'invited'",
            },
          ],
        },
        [
          _vm.is_user_active
            ? _c("linked-users", {
                attrs: { user: _vm.user, admin: true },
                on: { show: _vm.handleShowUser },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.user_settings_visible,
            width: "50%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.user_settings_visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "user-settings" },
            [
              !_vm.reset_password
                ? _c("p", { staticClass: "info" }, [
                    _vm._v(" Set a new temporary password for this user. "),
                  ])
                : _vm._e(),
              _vm.reset_password === true && _vm.temp_password
                ? _c("p", { staticClass: "info" }, [
                    _c("i", {
                      staticClass: "el-icon-circle-check",
                      staticStyle: { color: "green" },
                    }),
                    _vm._v(" Temporary password for this user is: "),
                    _c(
                      "b",
                      {
                        staticClass: "password",
                        on: {
                          click: () => {
                            _vm.copyToClipboard(_vm.temp_password)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.temp_password) + " ")]
                    ),
                    _c("br"),
                    _c("span", { staticClass: "footnote" }, [
                      _vm._v(
                        " (Copy this password now, it won't be shown again.) "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.reset_password === true && !_vm.temp_password
                ? _c("p", { staticClass: "info" }, [
                    _c("i", {
                      staticClass: "el-icon-circle-check",
                      staticStyle: { color: "green" },
                    }),
                    _vm._v(
                      " Temporary password has been sent to this user's contact email. "
                    ),
                  ])
                : _vm._e(),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "pwd-button",
                          attrs: {
                            disabled: _vm.reset_password === false,
                            loading: _vm.reset_password === false,
                            type: "primary",
                          },
                          on: { click: () => _vm.handleResetPassword(true) },
                        },
                        [_vm._v(" Reset password & view ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "pwd-button",
                          attrs: {
                            disabled:
                              _vm.reset_password === false ||
                              !_vm.user.contact_email,
                            loading: _vm.reset_password === false,
                            type: "primary",
                          },
                          on: { click: () => _vm.handleResetPassword(false) },
                        },
                        [_vm._v(" Reset password & send email ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("hr"),
              _vm.is_user_active
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.deactivate_dialog_visible = true
                        },
                      },
                    },
                    [_vm._v(" Deactivate user ")]
                  )
                : _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.changeUserStatus },
                    },
                    [_vm._v(" Activate user ")]
                  ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Warning",
            visible: _vm.deactivate_dialog_visible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deactivate_dialog_visible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(" Are you sure you want to deactivate this participant?"),
            _c("br"),
            _vm._v(
              " Any accounts monitoring, or monitored by, this user will be disconnected from it. "
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deactivate_dialog_visible = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", loading: _vm.changing_user_status },
                  on: { click: _vm.changeUserStatus },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.is_user_active ? "Deactivate" : "Activate") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }