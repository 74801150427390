var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-contact" },
    [
      _c(
        "div",
        {
          staticClass: "header",
          class: { editing: _vm.editing },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleExpanded.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "header-text" }, [
            _c("span", { staticClass: "name" }, [
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ]),
            _vm.contact.relationship
              ? _c("span", { staticClass: "relationship" }, [
                  _vm._v(" — " + _vm._s(_vm.contact.relationship) + " "),
                ])
              : _vm._e(),
          ]),
          !_vm.editing
            ? _c("el-button", {
                staticClass: "edit",
                attrs: { icon: "el-icon-edit" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.startEditing.apply(null, arguments)
                  },
                },
              })
            : _c("el-button", {
                staticClass: "save",
                attrs: {
                  icon: _vm.loading ? "el-icon-loading" : "el-icon-check",
                  disabled: !_vm.formValid || _vm.loading,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.saveContact.apply(null, arguments)
                  },
                },
              }),
          !_vm.editing
            ? _c(
                "el-popconfirm",
                {
                  staticClass: "deleteConfirm",
                  attrs: { title: "Delete this contact?" },
                  on: { confirm: _vm.deleteContact },
                },
                [
                  _c("el-button", {
                    staticClass: "delete",
                    attrs: { slot: "reference", icon: "el-icon-delete" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                    slot: "reference",
                  }),
                ],
                1
              )
            : _c("el-button", {
                staticClass: "cancel",
                attrs: {
                  icon: _vm.loading ? "el-icon-loading" : "el-icon-close",
                  disabled: _vm.loading,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.cancelEditing.apply(null, arguments)
                  },
                },
              }),
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editing || _vm.expanded,
              expression: "editing || expanded",
            },
          ],
          staticClass: "userContactForm",
          class: { readonly: !_vm.editing },
          attrs: { "label-position": "left", "label-width": "120px" },
          model: {
            value: _vm.editingContact,
            callback: function ($$v) {
              _vm.editingContact = $$v
            },
            expression: "editingContact",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "name", label: "Full Name" } },
            [
              _c("el-input", {
                ref: "nameInput",
                attrs: {
                  name: "name",
                  type: "text",
                  readonly: !_vm.editing,
                  "suffix-icon": _vm.editing ? "" : "el-icon-copy-document",
                },
                on: { focus: _vm.handleFocus },
                model: {
                  value: _vm.editingContact.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.editingContact, "name", $$v)
                  },
                  expression: "editingContact.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "relationship", label: "Relationship" } },
            [
              _c("el-input", {
                attrs: {
                  name: "relationship",
                  type: "text",
                  readonly: !_vm.editing,
                  "suffix-icon": _vm.editing ? "" : "el-icon-copy-document",
                },
                on: { focus: _vm.handleFocus },
                model: {
                  value: _vm.editingContact.relationship,
                  callback: function ($$v) {
                    _vm.$set(_vm.editingContact, "relationship", $$v)
                  },
                  expression: "editingContact.relationship",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "mobile", label: "Mobile" } },
            [
              _c("el-input", {
                attrs: {
                  name: "mobile",
                  type: "tel",
                  readonly: !_vm.editing,
                  "suffix-icon": _vm.editing ? "" : "el-icon-copy-document",
                },
                on: { focus: _vm.handleFocus },
                model: {
                  value: _vm.editingContact.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.editingContact, "mobile", $$v)
                  },
                  expression: "editingContact.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "email", label: "Email" } },
            [
              _c("el-input", {
                attrs: {
                  name: "email",
                  type: "email",
                  readonly: !_vm.editing,
                  "suffix-icon": _vm.editing ? "" : "el-icon-copy-document",
                },
                on: { focus: _vm.handleFocus },
                model: {
                  value: _vm.editingContact.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.editingContact, "email", $$v)
                  },
                  expression: "editingContact.email",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }