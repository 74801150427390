<template>
    <div class="comments-block">
        <ul>
            <comment-row
                v-for="comment in comments"
                :key="comment.id"
                :comment="comment"
                @delete="deleteComment"
            />
        </ul>
        <div class="new">
            <el-input
                v-model="text"
                name="text"
                type="text"
                :maxlength="150"
                placeholder="Add comment"
                size="mini"
                @keyup.enter.native="addComment"
            />
            <el-button
                size="mini"
                type="primary"
                icon="el-icon-d-arrow-right"
                @click="addComment"
            />
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';

import CommentRow from '@/views/components/CommentRow';

export default {
    name: 'comments-block',
    components: {
        CommentRow,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            text: null,
            comments: [],
        };
    },
    computed: {
        currentUser() {
            return firebase.auth().currentUser;
        },
    },
    mounted() {
        this.$bind(
            'comments',
            this.$fire
                .collection('comments')
                .where('to_user', '==', this.$fire.doc(`users/${this.user.id}`))
                .orderBy('date', 'desc'),
            {maxRefDepth: 1}
        );
    },
    methods: {
        async addComment() {
            let commentText = this.text;
            this.text = '';
            await this.$fire.collection('comments').add({
                to_user: this.$fire.doc(`users/${this.user.id}`),
                posted_by: this.$fire.doc(`users/${this.currentUser.uid}`),
                text: commentText,
                date: new Date(),
            });
        },

        async deleteComment(comment) {
            await this.$fire.doc(`comments/${comment.id}`).delete();
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.comments-block {
    margin-bottom: 20px;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        max-height: 130px;
        overflow: auto;
    }

    .new {
        margin-top: 10px;
        display: flex;
        flex-direction: row;

        .el-input {
            margin-right: 5px;
        }
    }
}
</style>
